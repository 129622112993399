import * as THREE from "three";
import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";

const Particle = ({ position, color, onRemove, trigger }) => {
  const velocity = useRef(
    new THREE.Vector3(
      Math.random() * 0.1 - 0.05,
      Math.random() * 0.12 - 0.05,
      Math.random() * 0.1 - 0.05
    )
  );
  const meshRef = useRef();

  useFrame((_, delta) => {
    if (meshRef.current) {
      meshRef.current.position.add(velocity.current);
      velocity.current.y -= 0.03 * delta; // gravity

      if (!trigger) onRemove();
    }
  });

  return (
    <mesh ref={meshRef} position={position}>
      <sphereGeometry args={[0.07, 8, 8]} />
      <meshStandardMaterial color={color} emissive={color} roughness={0.1} />
    </mesh>
  );
};
export default Particle;
