import { Link } from "react-router-dom";

const Info = () => {
  return (
    <>
      <div>info</div>
      <Link to="/">home</Link>
    </>
  );
};
export default Info;
