import { Box, Center, Sphere, Text3D } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import * as THREE from "three";

const World = () => {
  const boxGeometry = new THREE.BoxGeometry(0.1, 1);

  return (
    <>
      <group position={[0, 0, 0]}>
        <RigidBody type="kinematicPosition" position={[0, -0.5, 0]}>
          <mesh
            position={[0, -1, 0]}
            geometry={boxGeometry}
            rotation-z={-Math.PI * 0.5}
            scale={(40, 0.2, 40)}
          >
            <meshStandardMaterial />
          </mesh>
        </RigidBody>
      </group>
      <RigidBody colliders="cuboid" position={[0, 3, -10]}>
        <Center>
          <Text3D letterSpacing={-0.06} size={1} font="/Inter_Bold.json">
            Strampelli Gabriele
            <meshNormalMaterial />
          </Text3D>
        </Center>
      </RigidBody>
      <RigidBody
        colliders="cuboid"
        position={[10, 3, -10]}
        rotation-y={Math.PI / 0.6}
      >
        <Center>
          <Text3D letterSpacing={-0.06} size={1} font="/Inter_Bold.json">
            Work In Progress
            <meshNormalMaterial />
          </Text3D>
        </Center>
      </RigidBody>
      <RigidBody colliders="ball" position={[5, 3, -5]}>
        <Sphere scale={0.5}>
          <meshNormalMaterial />
        </Sphere>
      </RigidBody>
      <RigidBody colliders="cuboid" position={[5.8, 3, -5]}>
        <Box scale={0.5} args={[1, 8, 1]}>
          <meshNormalMaterial />
        </Box>
      </RigidBody>
      <directionalLight intensity={5} position={[1, 1, 1]} />
      <ambientLight intensity={1} />
    </>
  );
};
export default World;
