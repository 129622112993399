const LoaderCustom = () => {
  return (
    <div style={containerStyle}>
      <div style={ringStyle}></div>
      <div style={{ ...ringStyle, animationDelay: "-0.3s" }}></div>
      <div style={{ ...ringStyle, animationDelay: "-0.6s" }}></div>
    </div>
  );
};

// Stili inline per il container e i cerchi
const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#171717",
};

const ringStyle = {
  position: "absolute",
  border: "4px solid transparent",
  borderTop: "4px solid white",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: "spin 1.2s linear infinite",
};

// Aggiunta dinamica dei keyframes nell'elemento DOM
const addKeyframes = () => {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerHTML = `
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `;
  document.head.appendChild(styleSheet);
};

// Assicuriamo che i keyframes siano caricati
addKeyframes();

export default LoaderCustom;
