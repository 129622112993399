import { KeyboardControls, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Physics, Debug } from "@react-three/rapier";
import World from "../components/World";
import Player from "../components/Player";

const Game = () => {
  return (
    <>
      <KeyboardControls
        map={[
          { name: "forward", keys: ["ArrowUp", "KeyW"] },
          { name: "backward", keys: ["ArrowDown", "KeyS"] },
          { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
          { name: "rightward", keys: ["ArrowRight", "KeyD"] },
          { name: "jump", keys: ["Space"] },
        ]}
      >
        <div className="home">
          <Canvas className="homeCanvas">
            <Physics>
              <Debug />
              <OrbitControls />
              <World />
              <Player />
            </Physics>
          </Canvas>
        </div>
      </KeyboardControls>
    </>
  );
};
export default Game;
