import { Environment, Loader, Stats } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import PerspectiveCameraTour from "../components/PerspectiveCameraTour";
import { Suspense, useEffect, useState } from "react";
import TutorialAlert from "../components/TutorialAlert";

const VirtualTour = () => {
  const [onCloseTutorial, setOnCloseTutorial] = useState(true);

  useEffect(() => {
    // Controlla se il tutorial è già stato chiuso nel local storage
    const isTutorialClosed = localStorage.getItem("tutorialTour");
    if (isTutorialClosed) {
      setOnCloseTutorial(false);
    }
  }, []);
  /*  */
  const closeTutorial = () => {
    // Imposta lo stato su "false" e salva nel local storage
    setOnCloseTutorial(false);
    localStorage.setItem("tutorialTour", "true");
  };
  return (
    <>
      {onCloseTutorial ? (
        <TutorialAlert
          gif1={"face"}
          children1={
            "(give all permissions) stand in front of the screen and camera, rotate your head to turn the camera"
          }
          gif2={"eye"}
          children2={
            "look around to change the orientation of the camera (it's a demo, i'm working on it)"
          }
          onClose={closeTutorial}
        />
      ) : null}
      <div className="tour">
        <Canvas className="tourCanvas">
          <Suspense fallback={null}>
            <Environment
              background
              files={[
                "environments/px.jpg",
                "environments/nx.jpg",
                "environments/py.jpg",
                "environments/ny.jpg",
                "environments/pz.jpg",
                "environments/nz.jpg",
              ]}
              resolution={4000}
              blur={0}
            />
            <PerspectiveCameraTour />
            <Stats />
          </Suspense>
        </Canvas>
        <Loader />
      </div>
    </>
  );
};

export default VirtualTour;
