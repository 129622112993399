import * as THREE from "three";
import { Box, useGLTF, useKeyboardControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import {
  BallCollider,
  CapsuleCollider,
  CuboidCollider,
  RigidBody,
  useRapier,
} from "@react-three/rapier";
import { useEffect, useRef, useState } from "react";

export default function Player() {
  const { scene, animations } = useGLTF("./bicicletta.glb");
  const [subscribeKeys, getKeys] = useKeyboardControls();
  const body = useRef();
  const mixer = useRef();
  const triciclo = useRef();
  const { rapier, world } = useRapier();
  const rapierWorld = world.raw();

  const [smoothedCameraPosition] = useState(() => new THREE.Vector3());
  const [smoothedCameraTarget] = useState(() => new THREE.Vector3());

  mixer.current = new THREE.AnimationMixer(scene);
  mixer.current.clipAction(animations[0]);
  const animationDuration = animations[0].duration;
  mixer.current.setTime(animationDuration);

  useEffect(() => {
    const unsubscribe = subscribeKeys(
      (state) => state.jump,
      (value) => {
        if (value) {
          jump();
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const jump = () => {
    const origin = body.current.translation();
    origin.y -= 0.31;
    const direction = { x: 0, y: -1, z: 0 };
    const ray = new rapier.Ray(origin, direction);
    const hit = rapierWorld.castRay(ray, 10, true);
    if (hit.toi < 0.15) {
      body.current.applyImpulse({ x: 0, y: 2, z: 0 });
    }
  };
  useFrame((state, delta) => {
    const { rightward, backward, forward, leftward } = getKeys();

    const bodyPosition = body.current.translation();

    const modelDirection = new THREE.Vector3();
    scene.getWorldDirection(modelDirection);
    const impulseStrength = 3 * delta;
    const impulse = new THREE.Vector3();
    const rotationSpeed = 1.5;

    mixer.current.update(delta);

    if (forward || backward) {
      impulse
        .copy(modelDirection)
        .multiplyScalar(forward ? -impulseStrength : impulseStrength);
      mixer.current._actions[0].timeScale = forward ? 1 : -1;
      mixer.current._actions[0].paused = false;
      mixer.current._actions[0].play();
    } else {
      mixer.current._actions[0].paused = true;
    }

    if (leftward || rightward) {
      const targetRotationY = leftward ? -1 : -2;
      const currentRotationY = scene.children[4].rotation.y;
      const lerpedRotationY = THREE.MathUtils.lerp(
        currentRotationY,
        targetRotationY,
        delta * 2
      );

      scene.children[4].rotation.y = lerpedRotationY;

      const rotationSpeedDelta = (leftward ? 1 : -1) * rotationSpeed * delta;
      triciclo.current.rotation.y += rotationSpeedDelta;
    }

    const cameraPosition = new THREE.Vector3();
    cameraPosition.copy(bodyPosition).add(new THREE.Vector3(0, 5.65, 5.25));

    const cameraTarget = new THREE.Vector3();
    cameraTarget.copy(bodyPosition).add(new THREE.Vector3(0, 0.25, 0));

    smoothedCameraPosition.lerp(cameraPosition, 0.1);
    smoothedCameraTarget.lerp(cameraTarget, 0.1);

    state.camera.position.copy(smoothedCameraPosition);
    state.camera.lookAt(smoothedCameraTarget);

    body.current.applyImpulse(impulse);
  });
  return (
    <>
      <RigidBody
        ref={body}
        colliders={false}
        restitution={0.5}
        mass={0.5}
        friction={0.1}
        position-y={1}
      >
        <group ref={triciclo}>
          <primitive object={scene} scale={1.2} position-z={-0.01} />

          <CuboidCollider position={[0, 0.35, 0]} args={[0.4, 0.36, 0.45]} />
        </group>
      </RigidBody>
    </>
  );
}
