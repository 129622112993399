import * as THREE from "three";
import React, { useState, useEffect } from "react";
import Particle from "./Particle";

const Confetti = ({ color, trigger }) => {
  const [particles, setParticles] = useState([]);
  const removeParticle = (id) => {
    setParticles(particles.filter((particle) => particle.id !== id));
  };
  useEffect(() => {
    const newParticles = [];
    // const pos = fireworkRef.current.position;
    const pos = [0, 20, 0];
    for (let i = 0; i < 800; i++) {
      //const position = new THREE.Vector3(pos.x, pos.y, pos.z);
      const position = new THREE.Vector3(pos[0], pos[1], pos[2]);
      newParticles.push(
        <Particle
          key={Math.random()}
          position={position}
          color={color}
          onRemove={() => removeParticle(particles.id)}
          trigger={trigger}
        />
      );

      setParticles([...particles, ...newParticles]);
    }
  }, [trigger]);

  return <>{particles}</>;
};

export default Confetti;
