import { Text, Text3D, useAnimations } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Confetti from "./Confetti";

export const BoxAffari = ({
  position,
  rotation,
  index,
  state,
  removeFromState,
  clickedIndex,
  setClickedIndex,
  fadeText,
}) => {
  const [model, setModel] = useState(undefined);
  const [clickedIndices, setClickedIndices] = useState([]);
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const [checkAnimation, setCheckAnimation] = useState(false);

  const animations = useAnimations(
    model ? model.animations : [],
    model ? model.scene : []
  );
  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load("./scatola_affari_test.glb", setModel);
  }, []);

  const handleClickedIndex = async () => {
    await fadeText();
    setClickedIndex(false);
    setTriggerConfetti(false);
  };

  useEffect(() => {
    // Imposta clickedIndex su true solo se ci sono duplicati
    if (animations.mixer.stats.actions.inUse === 1) {
      setClickedIndex(false);
      setTriggerConfetti(false);
    } else {
      if (model) {
        const action = animations.actions.BoxAction;
        action.clampWhenFinished = true;
        action.repetitions = false;
        action.play();
        setTriggerConfetti(true);
        const handlePauseChange = (event) => {
          if (event.action.paused) {
            removeFromState(state[index]);
            handleClickedIndex();
          }
        };

        animations.mixer.addEventListener("finished", handlePauseChange);
        return () => {
          animations.mixer.removeEventListener("finished", handlePauseChange);
        };
      }
    }
  }, [clickedIndices, !checkAnimation]);

  const handleBoxClick = () => {
    setClickedIndex(true);
    setClickedIndices((prevIndices) => [...prevIndices, index]);

    if (animations.mixer.stats.actions.inUse === 0) {
      setCheckAnimation(!checkAnimation);
    }
  };

  return model ? (
    <>
      <primitive
        object={model.scene}
        rotation={rotation}
        position={position}
        onClick={clickedIndex ? null : handleBoxClick}
        castShadow
        receiveShadow
      >
        <Text3D
          position={[-0.85, 0.2, 1]}
          font="/Inter_Bold.json"
          onClick={(e) => {
            e.stopPropagation(); // Evita che il click venga catturato dal Text3D
            if (!clickedIndex) handleBoxClick(); // Inoltra il click al comportamento del primitive
          }}
        >
          {index}
        </Text3D>
      </primitive>
      {triggerConfetti ? (
        <Confetti color={state[index].colore} trigger={triggerConfetti} />
      ) : null}
    </>
  ) : null;
};
