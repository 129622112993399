import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <>
      <div>ERROR 404</div>
      <Link to="/">TORNA ALLA HOME</Link>
    </>
  );
};
export default NoPage;
