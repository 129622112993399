import React from "react";

const ErrorAlert = ({ children, onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9998,
        pointerEvents: "auto",
        padding: "1em", // Ensure padding around the content
        boxSizing: "border-box", // Include padding in the width calculation
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "white",
          padding: "1em",
          borderRadius: "1em",
          pointerEvents: "all",
          width: "100%", // Make the width responsive
          maxWidth: "300px", // Maximum width of the modal
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxSizing: "border-box", // Include padding in the width calculation
          boxShadow: "0px 0px 15px white",
          WebkitBoxShadow: "0px 0px 15px white",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <strong style={{ flexGrow: 1, textAlign: "center" }}>Error</strong>
          <button
            onClick={onClose}
            style={{
              background: "transparent",
              border: "none",
              fontSize: "1.2em",
              cursor: "pointer",
            }}
          >
            {"\u2716"}
          </button>
        </div>
        <div style={{ marginTop: "1em" }}>{children}</div>
      </div>
    </div>
  );
};

export default ErrorAlert;
