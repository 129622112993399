import { Float } from "@react-three/drei";
import { BoxAffari } from "./BoxAffari";
import { useEffect, useState } from "react";

const AffariTuoiGameContent = ({ state, removeFromState, fadeText }) => {
  const [clickedIndex, setClickedIndex] = useState(false);
  const [arrayPRI, setArrayPRI] = useState([]);

  useEffect(() => {
    let totalLength = state.length * 0.7;
    const middleIndex = Math.floor(state.length / 2);
    const firstHalf = state.slice(0, middleIndex);
    const secondHalf = state.slice(middleIndex);

    const newArray = [];

    [firstHalf, secondHalf].forEach((halfState, halfIndex) => {
      halfState.forEach((dati, index) => {
        const angle = (index / (halfState.length - 1)) * Math.PI;
        let posX = Math.sin(angle + Math.PI / 2) * totalLength;
        let posZ = Math.cos(angle + Math.PI / 2) * totalLength;
        const rotationY = Math.atan2(0 - posX, 0 - posZ);
        let posY = halfIndex === 0 ? -4 : 0;

        newArray.push({
          position: [posX, posY, posZ + 1],
          rotation: [0, rotationY, 0],
          index: halfIndex * middleIndex + index,
        });
      });
    });

    setArrayPRI(newArray);
  }, [state]);

  return (
    <>
      {arrayPRI.map(({ position, rotation, index }) => (
        <Float
          key={index}
          speed={5}
          floatingRange={[1, 1.2]}
          floatIntensity={1}
          rotationIntensity={0.1}
        >
          <BoxAffari
            position={position}
            rotation={rotation}
            index={index}
            state={state}
            removeFromState={removeFromState}
            clickedIndex={clickedIndex}
            setClickedIndex={setClickedIndex}
            fadeText={fadeText}
          />
        </Float>
      ))}
    </>
  );
};

export default AffariTuoiGameContent;
