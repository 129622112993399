import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import ErrorAlert from "../components/ErrorAlert";
import TutorialAlert from "../components/TutorialAlert";

const TabellaAffariTuoi = () => {
  /* tutorial */
  const [onCloseTutorial, setOnCloseTutorial] = useState(true);

  useEffect(() => {
    // Controlla se il tutorial è già stato chiuso nel local storage
    const isTutorialClosed = localStorage.getItem("tutorialTabClosed");
    if (isTutorialClosed) {
      setOnCloseTutorial(false);
    }
  }, []);
  /*  */
  const closeTutorial = () => {
    // Imposta lo stato su "false" e salva nel local storage
    setOnCloseTutorial(false);
    localStorage.setItem("tutorialTabClosed", "true");
  };

  const [dati, setDati] = useState([]);
  const [error, setError] = useState("");
  const [inputValori, setInputValori] = useState({
    colore: "",
    premio: "",
  });
  const [colorSelected, setColorSelected] = useState("white");

  const navigate = useNavigate();

  const aggiungiDato = (colore) => {
    setColorSelected("white");
    if (dati.length <= 30) {
      if ((colore != "") & (inputValori.premio != "")) {
        setDati([...dati, { colore: colore, premio: inputValori.premio }]);
        setError("");
      } else {
        setError("Insert Color and Prize");
      }
      setInputValori({
        colore: "",
        premio: "",
      });
    } else setError("too many prizes");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValori({
      ...inputValori,
      [name]: value,
    });
  };

  const hanleGamesButton = () => {
    if (dati.length <= 8) {
      setError("error put at least 8 prizes");
    } else {
      navigate("/AffariTuoiGame", { state: dati });
    }
  };

  const handleColorButton = (name) => {
    setColorSelected(name);
    if (name == "#FF4500") {
      setInputValori({ ...inputValori, colore: "red" });
    } else if (name == "#007FFF") {
      setInputValori({ ...inputValori, colore: "blue" });
    }
  };
  const hanleDelete = (index) => {
    const updatedDati = dati.filter((_, i) => i !== parseInt(index));
    setDati(updatedDati);
  };

  const formatPremio = (premio) => {
    let formattedPremio = "";
    let currentLength = 0;

    for (let i = 0; i < premio.length; i++) {
      const char = premio[i];
      formattedPremio += char;
      if (char !== " ") {
        currentLength++;
        if (currentLength >= 12) {
          formattedPremio += " ";
          currentLength = 0;
        }
      } else {
        currentLength = 0;
      }
    }
    return formattedPremio;
  };
  const closeAlert = () => {
    setError("");
  };

  return (
    <>
      {onCloseTutorial ? (
        <TutorialAlert
          children2={
            "Select color and prize to complete your table (put at least 8 prizes)"
          }
          gif2={"click"}
          children1={
            "game inspired by the TV show AFFARI TUOI, Complete the table to access the game (to play in company)"
          }
          gif1={null}
          onClose={closeTutorial}
        />
      ) : null}
      <div className="container">
        <div className="formContainer">
          <div className="column">
            <Button
              color="#FF4500"
              onClick={() => handleColorButton("#FF4500")}
            >
              Red
            </Button>
            <Button
              color="#007FFF"
              onClick={() => handleColorButton("#007FFF")}
            >
              Blue
            </Button>
          </div>
          <div className="column">
            <input
              type="text"
              name="premio"
              style={{
                color: colorSelected,
              }}
              value={inputValori.premio}
              onChange={handleInputChange}
              className="input_premio"
              placeholder="Insert Prize"
              maxLength={33}
            />
          </div>
          <div className="column">
            <Button
              color="#39FF14"
              onClick={() => aggiungiDato(inputValori.colore)}
            >
              Add Box
            </Button>
          </div>
        </div>
        <div className="tableContainer">
          <table>
            <thead className="thead">
              <tr>
                <th style={{ paddingInline: "3px" }}>Number</th>
                <th style={{ paddingInline: "3px" }}>Color</th>
                <th style={{ paddingInline: "3px" }}>Prize</th>
                <th style={{ paddingInline: "3px" }}>Delete</th>
              </tr>
            </thead>
            <tbody className="tbodyA">
              {dati.map((dato, index) => (
                <tr key={index}>
                  <td className="eRow">{index}</td>
                  <td className="eRow">{dato.colore}</td>
                  <td className="eRow">{formatPremio(dato.premio)}</td>
                  <td>
                    <button
                      id={index}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "2px solid white",
                        padding: "0.2em 0.4em",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "bold",
                        outline: "none",
                      }}
                      onClick={(index) => hanleDelete(index.target.id)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            backgroundColor: "#191920",
            width: "100%",
            textAlign: "center",
            paddingBlock: "5px",
          }}
        >
          <Button onClick={hanleGamesButton} color={"#008cba"} id="#008cba">
            Play!
          </Button>
        </div>
        {error && <ErrorAlert onClose={closeAlert}>{error}</ErrorAlert>}
      </div>
    </>
  );
};

export default TabellaAffariTuoi;
