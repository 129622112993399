import React, { useEffect, useState } from "react";

const PrizeList = ({
  showPrizes,
  stateShuffle,
  stateNotShuffle,
  controlPrize,
}) => {
  const [showNumber, setShowNumber] = useState(false);
  const prizes = controlPrize ? stateShuffle : stateNotShuffle;
  useEffect(() => {
    if (stateNotShuffle === prizes) {
      setShowNumber(true);
    } else {
      setShowNumber(false);
    }
  }, [prizes]);

  const hasLongWord = (str) => {
    return /[a-zA-Z]{9,}/.test(str);
  };
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#cccccc5b",
      }}
    >
      {showPrizes ? (
        <div className="prizes_game">
          {prizes.map((dati, index) => (
            <div
              key={index}
              style={{
                flex: "1 0 30%",
                margin: "30px",
                maxWidth: "30%",
                overflow: "hidden",
              }}
            >
              <h4
                style={{
                  color: dati.colore,
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "700",
                  backgroundColor: "white",
                  padding: "5px 7px",
                  borderRadius: "5px",
                  lineHeight: "1",
                  border: `2px solid ${dati.colore}`,
                  textAlign: "center",
                  wordBreak: hasLongWord(dati.premio) ? "break-all" : "normal",
                }}
              >
                {showNumber ? `${index}) ` : null}

                {dati.premio}
              </h4>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PrizeList;
