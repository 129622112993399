import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Loader, OrbitControls } from "@react-three/drei";
import { useLocation } from "react-router-dom";
import CameraAffari from "../components/CameraAffari";
import PrizeList from "../components/PrizeList";
import AffariTuoiGameContent from "../components/AffariTuoiGameContent";
import Button from "../components/Button";
import FireWorks from "../components/FireWorks";
import useDeviceType from "../utilities/DeviceType";
import TutorialAlert from "../components/TutorialAlert";

const AffariTuoiGame = () => {
  let { state: initialState } = useLocation();
  const [stateShuffle, setState] = useState(initialState);
  const [showPrizes, setShowPrizes] = useState(false);
  const [controlPrize, setControlPrize] = useState(true);
  const [positionScreen, setPositionScreen] = useState();
  const [indexlock, setIndexlock] = useState(undefined);
  const [fireWorksArray, setFireWorksArray] = useState(false);
  const textOpacityRef = useRef(0);
  const textRef = useRef();
  const isMobile = useDeviceType();
  const [onCloseTutorial, setOnCloseTutorial] = useState(true);

  const removeFromState = (indexToRemove) => {
    setIndexlock(indexToRemove);
    setState((prevState) =>
      prevState.filter((dati, index) => dati !== indexToRemove)
    );
    setFireWorksArray([true, indexToRemove.colore]);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (indexlock !== undefined) {
      fadeText();
    }
  }, [indexlock]);

  const fadeText = () => {
    return new Promise((resolve) => {
      let opacity = textOpacityRef.current;
      const increment = 0.015;
      const duration = 50; // milliseconds
      const fadeIn = () => {
        opacity += increment;
        if (opacity >= 1) {
          opacity = 1;
          clearInterval(fadeInInterval);
          if (stateShuffle.length >= 2) {
            setTimeout(() => {
              fadeOut();
            }, 2000);
          }
        }
        textOpacityRef.current = opacity;
        if (textRef.current) {
          textRef.current.style.opacity = opacity;
        }
      };

      const fadeOut = () => {
        const fadeOutInterval = setInterval(() => {
          opacity -= increment;
          if (opacity <= 0) {
            opacity = 0;
            clearInterval(fadeOutInterval);
            setIndexlock(undefined);
            setFireWorksArray([false]);
            resolve();
          }
          textOpacityRef.current = opacity;
          if (textRef.current) {
            textRef.current.style.opacity = opacity;
          }
        }, duration);
      };

      const fadeInInterval = setInterval(fadeIn, duration);
    });
  };

  const handleCheckboxClick = () => {
    setControlPrize(!controlPrize);
  };

  useEffect(() => {
    const isTutorialClosed = localStorage.getItem("tutorialAffariGame");
    if (isTutorialClosed) {
      setOnCloseTutorial(false);
    }

    setState(shuffleArray([...initialState]));
    setPositionScreen(window.innerHeight > window.innerWidth);
    const handleResize = () => {
      setPositionScreen(window.innerHeight > window.innerWidth);
    };

    // Aggiungi l'event listener quando il componente è montato
    window.addEventListener("resize", handleResize);

    // Rimuovi l'event listener quando il componente è smontato
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeTutorial = () => {
    // Imposta lo stato su "false" e salva nel local storage
    setOnCloseTutorial(false);
    localStorage.setItem("tutorialAffariGame", "true");
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        {onCloseTutorial ? (
          <TutorialAlert
            children1={
              isMobile
                ? "hold and drag to move the camera"
                : "move the pointer to roteate the camera"
            }
            gif1={"move"}
            children2={
              "click on the box to reveal prizes (you can see all the prizes from the button and decide whether to see them in order or shuffle)"
            }
            gif2={"click"}
            onClose={closeTutorial}
          />
        ) : null}
        <div className="home">
          <Canvas className="homeCanvas">
            <Suspense fallback={null}>
              <pointLight
                position={[0, 2, 0]}
                intensity={1}
                distance={60}
                decay={0}
              />
              <pointLight
                position={[0, -4, 0]}
                intensity={1}
                distance={60}
                decay={0}
              />
              <ambientLight intensity={0.5} />
              <AffariTuoiGameContent
                state={initialState}
                removeFromState={removeFromState}
                fadeText={fadeText}
              />

              {isMobile ? (
                <OrbitControls
                  makeDefault
                  enableZoom={false}
                  maxPolarAngle={2}
                  minPolarAngle={1}
                  maxAzimuthAngle={positionScreen ? Math.PI / 2 : Math.PI / 4} // Limit rotation to the right
                  minAzimuthAngle={positionScreen ? -Math.PI / 2 : -Math.PI / 4} // Limit rotation to the left
                />
              ) : (
                <CameraAffari />
              )}
            </Suspense>
          </Canvas>
          <FireWorks active={fireWorksArray} />
          <div
            style={{
              position: "absolute",
              right: "30px",
              top: "25px",
              textAlign: "center",
            }}
          >
            <Button
              onClick={() => setShowPrizes(!showPrizes)}
              className="togglePrizesButton"
              color={"#0175FF"}
            >
              {showPrizes ? "Hide Prizes" : "Show Prizes"}
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <h4
                style={{
                  fontFamily: "'Inter', sans-serif",
                  paddingTop: "1px",
                  color: "white",
                  fontSize: "13px",
                }}
              >
                Prizes Shuffle?
              </h4>
              <input
                className="check_shuffle"
                type="checkbox"
                checked={controlPrize}
                onChange={handleCheckboxClick}
                style={{
                  fontFamily: "'Inter', sans-serif",
                  marginLeft: "10px",
                  color: "#0175FF",
                }}
              />
            </div>
          </div>
          {/* <button
          onClick={() => console.log(showPrizes)}
          style={{
            position: "absolute",
            left: "30px",
            top: "25px",
            fontFamily: "'Inter', sans-serif",
          }}
          className="togglePrizesButton"
        >
          {"Change Box"}
        </button> */}
          <h1
            ref={textRef}
            style={{
              position: "fixed",
              color: indexlock?.colore,
              fontFamily: "'Inter', sans-serif",
              padding: "5px",
              maxWidth: "calc(100% - 30px)", // Assicurati che l'elemento non superi i bordi della pagina
              wordWrap: "break-word", // Gestisci il ritorno a capo del testo lungo
              overflow: "hidden", // Nascondi eventuale overflow
              textShadow:
                "1px 1px 1px white, -1px -1px 0px white, 1px -1px 0px white, -1px 1px 0px white",
              letterSpacing: "1px",
              textAlign: "center",
              opacity: "0.5",
              opacity: textOpacityRef.current,
              transition: "opacity 0.5s",
            }}
          >
            {indexlock?.premio}
          </h1>
          <PrizeList
            showPrizes={showPrizes}
            stateShuffle={stateShuffle}
            stateNotShuffle={initialState}
            controlPrize={controlPrize}
          />
        </div>
      </Suspense>
    </>
  );
};

export default AffariTuoiGame;
