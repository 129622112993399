import React, { useEffect, useState } from "react";
import useDeviceType from "../utilities/DeviceType";

const TutorialAlert = ({ children1, children2, gif1, gif2, onClose }) => {
  const [gifUp, setGifUp] = useState();
  const [gifDown, setGifDown] = useState();
  const isMobile = useDeviceType();

  const getGif = (type, isMobile) => {
    if (type === "scroll") {
      return isMobile ? "/scroll_phone.gif" : "/scroll_mouse.gif";
    }
    if (type === "click") {
      return isMobile ? "/click_phone.gif" : "/click_mouse.gif";
    }
    if (type === "hold and drag") {
      return isMobile ? "/hold_drag_phone.gif" : "/hold_drag_mouse.gif";
    }
    if (type === "move") {
      return isMobile ? "/hold_drag_phone.gif" : "/move_mouse.gif";
    }
    if (type === "eye") {
      return "/eye.gif";
    }
    if (type === "face") {
      return "/face.png";
    }
    return null; // Caso predefinito se type non è "scroll" o "click"
  };

  useEffect(() => {
    setGifUp(getGif(gif1, isMobile));
    setGifDown(getGif(gif2, isMobile));
  }, [gif1, gif2, isMobile]); // Effetto che si aggiorna su cambio di gif1, gif2 o device

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9998,
        pointerEvents: "auto",
        padding: "2em",
        boxSizing: "border-box",
        fontFamily: "Inter, sans-serif",
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "2em",
          borderRadius: "1em",
          pointerEvents: "all",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "1em",
            right: "1em",
            background: "transparent",
            fontSize: "1.2em",
            cursor: "pointer",
            color: "white", // Forza il colore bianco
            border: "2px solid white",
            borderRadius: "5px",
            padding: "3px 5px",
          }}
        >
          {"\u2716"}
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Disposizione a colonna
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            flexGrow: 2,
            textAlign: "center",
            width: "100%", // Prendi tutto lo spazio
          }}
        >
          {/* Prima riga: GIF sopra e children sotto */}
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Disposizione verticale
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {gif1 ? (
                <img
                  src={gifUp}
                  alt="Tutorial"
                  style={{
                    maxWidth: "60%",
                    maxHeight: "60%",
                  }}
                />
              ) : null}
            </div>
            <div>{children1 ? <h1>{children1}</h1> : null}</div>
          </div>

          {/* Seconda riga: GIF sopra e children sotto */}
          {children2 && gif2 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Disposizione verticale
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {gif2 ? (
                  <img
                    src={gifDown}
                    alt="Tutorial"
                    style={{
                      maxWidth: "60%",
                      maxHeight: "60%",
                    }}
                  />
                ) : null}
              </div>
              <div>{children2 ? <h1>{children2}</h1> : null}</div>
            </div>
          ) : null}

          {/* Media Query per dispositivi mobili */}
          <style>
            {`
            h1{
            font-size: 1.7vw;
            }
      @media (max-width: 600px) {
        h1 {
          font-size: 4vw; /* Ridimensiona il testo per schermi piccoli */
        }
        img {
          max-width: 60%; /* Ridimensiona l'immagine */
          max-height: 300px; /* Riduci l'altezza massima su dispositivi mobili */
        }
      }
    `}
          </style>
        </div>
      </div>
    </div>
  );
};

export default TutorialAlert;
