import React, { useEffect, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { Vector3 } from "three";
import info from "../info.json";
import { OrbitControls } from "@react-three/drei";

const CameraControls = ({ takeMesh, selectedMesh }) => {
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, z: 5 });
  const [animateCamera, setAnimateCamera] = useState(false);

  const lerpFactor = 0.04;
  const { camera } = useThree();

  const getCameraPositionFromJSON = (meshName) => {
    if (info.cameraMoove[meshName]) {
      return info.cameraMoove[meshName];
    } else {
      return { x: 0, y: 0, z: 5 };
    }
  };

  useEffect(() => {
    const newCameraPosition = getCameraPositionFromJSON(takeMesh);
    setCameraPosition(newCameraPosition);
    setAnimateCamera(true);
  }, [takeMesh, selectedMesh]);

  useFrame((delta) => {
    if (animateCamera) {
      const roundedCameraPosition = {
        x: parseFloat(camera.position.x.toFixed(1)),
        y: parseFloat(camera.position.y.toFixed(1)),
        z: parseFloat(camera.position.z.toFixed(1)),
      };
      if (
        roundedCameraPosition.x !== cameraPosition.x ||
        roundedCameraPosition.y !== cameraPosition.y ||
        roundedCameraPosition.z !== cameraPosition.z
      ) {
        camera.position.lerp(cameraPosition, lerpFactor, delta);
        camera.lookAt(0, 0, 0);
      } else {
        setAnimateCamera(false);
      }
    }
  });

  return (
    <>
      {!animateCamera && (
        <OrbitControls
          makeDefault
          minDistance={2.2}
          maxDistance={10}
          enablePan={false}
        />
      )}
    </>
  );
};

export default CameraControls;
