import { Canvas } from "@react-three/fiber";
import { ContactShadows, Loader } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"; // Importa GLTFLoader
import React, { Suspense, useEffect, useState } from "react";
import "../App.css";
import infoJson from "../info.json";
import CameraControls from "../components/CameraControls";
import TutorialAlert from "../components/TutorialAlert";

const ModelViewer = () => {
  const [onCloseTutorial, setOnCloseTutorial] = useState(true);
  const [model, setModel] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false); // Stato per controllare il caricamento

  const [color, setColor] = useState("");
  const [selectColor, setSelectColor] = useState();

  const [mesh, setMesh] = useState("");
  const [selectedMesh, setSelectedMesh] = useState("");

  useEffect(() => {
    // Controlla se il tutorial è già stato chiuso nel local storage
    const isTutorialClosed = localStorage.getItem("tutorialModelViewerClosed");
    if (isTutorialClosed) {
      setOnCloseTutorial(false);
    }
  }, []);

  const closeTutorial = () => {
    // Imposta lo stato su "false" e salva nel local storage
    setOnCloseTutorial(false);
    localStorage.setItem("tutorialModelViewerClosed", "true");
  };

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      "./scarpa/shoe.gltf",
      (gltf) => {
        setModel(gltf.scene);
        setIsLoaded(true);
      },
      undefined,
      (error) => console.error("Errore nel caricamento del modello:", error)
    );
  }, []);

  useEffect(() => {
    if (mesh && mesh.material) {
      mesh.material.color.set(color);
      mesh.material.needsUpdate = true;
    }
  }, [color, selectColor, !mesh]);

  const handleClick = (e) => {
    const clickedMesh = e.intersections[0]?.object;
    setMesh(clickedMesh);
    setSelectedMesh(!selectedMesh);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor);
    setSelectColor(!selectColor);
  };

  return (
    <>
      {!isLoaded && <Loader />} {/* Mostra il loader finché non è caricato */}
      {isLoaded && (
        <>
          {onCloseTutorial && (
            <TutorialAlert
              children1={"hold and drag to move the camera"}
              gif1={"hold and drag"}
              children2={
                "Click on the shoe to select the part to modify and select a color to change it"
              }
              gif2={"click"}
              onClose={closeTutorial}
            />
          )}
          <div className="wrapper">
            <div className="card">
              <div className="product-canvas">
                <Canvas>
                  <Suspense fallback={<Loader />}>
                    <ambientLight intensity={1.5} />
                    <directionalLight position={[0, 5, 0]} intensity={0.7} />
                    <CameraControls
                      takeMesh={mesh.name}
                      selectedMesh={selectedMesh}
                    />
                    {model && (
                      <primitive object={model} onClick={handleClick} />
                    )}
                    <ContactShadows
                      position-y={-0.8}
                      opacity={0.4}
                      scale={5}
                      blur={2.4}
                    />
                  </Suspense>
                </Canvas>
              </div>
              <div className="button-container">
                {mesh &&
                  infoJson.meshColors[mesh.name] &&
                  Object.entries(infoJson.meshColors[mesh.name]).map(
                    ([name, value]) => (
                      <button
                        className="buttonColors"
                        key={name}
                        onClick={() => handleColorChange(value)}
                        style={{
                          color: value,
                        }}
                      ></button>
                    )
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModelViewer;
