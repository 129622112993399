import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { FaceControls, FaceLandmarker, OrbitControls } from "@react-three/drei";

const PerspectiveCameraTour = () => {
  const ref = useRef();
  const controls = useThree((state) => state.controls);
  const [userCam, setUserCam] = useState(0);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false);

  const moveCameraOnYAxis = (rotationY) => {
    const sensitivity = 0.02;
    if (rotationY > 0.2) {
      setUserCam((prevUserCam) => prevUserCam - sensitivity);
    } else if (rotationY < -0.2) {
      setUserCam((prevUserCam) => prevUserCam + sensitivity);
    }
  };

  const onVideoFrame = useCallback(
    (event) => {
      controls.detect(event.texture.source.data, event.time);
      const rotationY =
        ref.current.facemeshApiRef.current.outerRef.current.rotation._y;

      moveCameraOnYAxis(rotationY);
    },
    [controls]
  );
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => setCameraAccessGranted(true))
      .catch((error) => console.error("Error accessing camera:", error));
  }, []);

  useFrame((_, delta) => {
    if (ref.current) {
      ref.current.update(delta);
    }
  });
  return (
    <>
      {cameraAccessGranted ? (
        <group rotation-y={userCam}>
          <FaceLandmarker>
            <FaceControls
              ref={ref}
              makeDefault
              smoothTime={0.5}
              eyes={true}
              eyesAsOrigin={true}
              manualDetect
              onVideoFrame={onVideoFrame}
              manualUpdate
            />
          </FaceLandmarker>
        </group>
      ) : (
        <OrbitControls makeDefault dampingFactor={0.05} />
      )}
    </>
  );
};

export default PerspectiveCameraTour;
