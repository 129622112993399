import { Fireworks } from "fireworks-js";
import { useEffect, useRef } from "react";

const FireWorks = ({ active }) => {
  const fireworksContainerRef = useRef(null);
  const fireworksInstanceRef = useRef(null);

  useEffect(() => {
    if (active[0]) {
      // Determine hue range based on active[1]
      const hueRange =
        active[1] === "blue" ? { min: 220, max: 240 } : { min: 0, max: 20 };

      // Creiamo sempre una nuova istanza quando active diventa true
      fireworksInstanceRef.current = new Fireworks(
        fireworksContainerRef.current,
        {
          hue: hueRange,
        }
      );
      fireworksInstanceRef.current.start();
    } else {
      if (fireworksInstanceRef.current) {
        fireworksInstanceRef.current.waitStop();
        fireworksInstanceRef.current = null;
      }
    }
  }, [active]);

  return (
    <canvas
      ref={fireworksContainerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 9998,
      }}
    />
  );
};

export default FireWorks;
