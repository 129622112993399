import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ModelViewer from "./pages/ModelViewer";
import Info from "./pages/Info";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Game from "./pages/Game";
import VirtualTour from "./pages/VirtualTour";
import TabellaAffariTuoi from "./pages/TabellaAffariTuoi";
import AffariTuoiGame from "./pages/AffariTuoiGame";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/viewer" element={<ModelViewer />} />
          <Route path="/info" element={<Info />} />
          <Route path="/game" element={<Game />} />
          <Route path="/tour" element={<VirtualTour />} />
          <Route path="/TabellaAffariTuoi" element={<TabellaAffariTuoi />} />
          <Route path="/AffariTuoiGame" element={<AffariTuoiGame />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
