import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  ScrollControls,
  useScroll,
  Loader,
  Text,
} from "@react-three/drei";
import useRefs from "react-use-refs";
import { Suspense, useEffect, useState } from "react";
import TutorialAlert from "../components/TutorialAlert";
import VideoBackground from "../components/VideoBackground";
import LoaderCustom from "../components/LoaderCustom";

const Home = () => {
  const [onCloseTutorial, setOnCloseTutorial] = useState(true);
  const [okVideo, setOkVideo] = useState(false);

  const updateHeight = () => {
    const vh = window.innerHeight * 0.01; // Calcola 1% dell'altezza del viewport
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.querySelector(".home").style.height = `calc(var(--vh, 1vh) * 100)`;
  };

  useEffect(() => {
    // Controlla se il tutorial è già stato chiuso nel local storage
    const isTutorialClosed = localStorage.getItem("tutorialHomeClosed");
    if (isTutorialClosed) {
      setOnCloseTutorial(false);
    }
    /* fix canvas safari phone */
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const closeTutorial = () => {
    // Imposta lo stato su "false" e salva nel local storage
    setOnCloseTutorial(false);
    localStorage.setItem("tutorialHomeClosed", "true");
  };

  return (
    <>
      {!okVideo && <LoaderCustom />}
      <div className="home">
        <Suspense fallback={<Loader />}>
          {onCloseTutorial ? (
            <TutorialAlert
              children1={"scroll to roteate the computer"}
              gif1={"scroll"}
              children2={
                "click on the window of computer to enter in that page"
              }
              gif2={"click"}
              onClose={closeTutorial}
            />
          ) : null}
          <Canvas
            shadows
            dpr={[1, 2]}
            camera={{ position: [0, -3.2, 40], fov: 12 }}
            className="homeCanvas"
            gl={{
              outputColorSpace: "srgb",
              toneMapping: THREE.NoToneMapping,
            }}
          >
            <Suspense fallback={null}>
              <ScrollControls pages={15} damping={1} maxSpeed={0.2}>
                <Composition />
                <VideoBackground onVideoReady={() => setOkVideo(true)} />
              </ScrollControls>
            </Suspense>
          </Canvas>
          <Loader />
        </Suspense>
      </div>
    </>
  );
};

const Composition = () => {
  const scroll = useScroll();
  const [mixer, mbp16, textRef] = useRefs();

  /* const [fontSize, setFontSize] = useState(null);
    const [gradientTexture, setGradientTexture] = useState(null);
   */
  /* const [clickedMesh, setClickedMesh] = useState(false); */

  const { scene, animations } = useGLTF("./macAnimated.glb");

  const screen = scene.children[0].children[9];

  const sizeFont = window.innerWidth * 0.0008;
  const textMax = Math.min(Math.max(sizeFont, 0.25), 1.2);

  const stop = 0.999999;

  const handleClick = () => {
    const screenUrls = {
      screen1: "/viewer",
      screen2: "/TabellaAffariTuoi",
      screen3: "/Tour",
    };

    const url = screenUrls[screen.material.map.name];
    if (url) {
      window.location.href = url;
    }
  };

  const textureLoader = new THREE.TextureLoader();
  const textureScreen1 = textureLoader.load("./modelViewer.webp");
  textureScreen1.name = "screen1";
  textureScreen1.colorSpace = "srgb"; // Imposta lo spazio colore per la texture

  const textureScreen2 = textureLoader.load("./BoxGame.webp");
  textureScreen2.name = "screen2";
  textureScreen2.colorSpace = "srgb";

  const textureScreen3 = textureLoader.load("./virtualTour.webp");
  textureScreen3.name = "screen3";
  textureScreen3.colorSpace = "srgb";

  const textureNormal = textureLoader.load("./sfondoBlue.jpg");
  textureNormal.name = "Normal";
  textureNormal.colorSpace = "srgb";

  screen.material = new THREE.MeshBasicMaterial({
    map: textureNormal, // Imposta la texture di default
  });

  /* scroll.offset */
  useFrame((state, delta) => {
    const openScreen = scroll.range(0 / 20, 1.5 / 20);
    const rotate1 = scroll.range(1.5 / 20, 3 / 20);
    const rotate2 = scroll.range(8.5 / 20, 3 / 20);
    const rotate3 = scroll.range(15.5 / 20, 3 / 20);

    const screen1 = scroll.visible(3 / 20, 7 / 20);
    const screen2 = scroll.visible(10 / 20, 7 / 20);
    const screen3 = scroll.visible(14 / 20, 6 / 20);

    mbp16.current.rotation.y =
      Math.PI -
      Math.PI *
        2 *
        (rotate1 === 1 && rotate2 === 1
          ? rotate3
          : rotate1 === 1
          ? rotate2
          : rotate1);

    mixer.current = new THREE.AnimationMixer(scene);
    const action = mixer.current.clipAction(animations[0]);

    {
      openScreen <= stop ? action.play() : (action.pause = true);
    }

    const animationDuration = animations[0].duration;
    const animationTime = openScreen * animationDuration;
    mixer.current.setTime(animationTime);

    screen.material.map = screen1
      ? textureScreen1
      : screen2
      ? textureScreen2
      : screen3
      ? textureScreen3
      : textureNormal;
  });

  /*   useEffect(() => {
    const video = document.createElement("video");
    video.src = "/video_fumo_blu_2.mp4";
    video.crossOrigin = "anonymous";
    video.loop = true; // Disattiva il loop predefinito
    video.muted = true;
    video.playsInline = true;

    video.play();
    const texture = new THREE.VideoTexture(video);
    setGradientTexture(texture);

    

    setFontSize(textMax);
  }, []); */

  return (
    <>
      <Text
        ref={textRef}
        font="./Tanker-Regular.ttf"
        fontSize={textMax} // Usa fontSize dinamico
        position={[0, 3.4, 0]} // Posiziona il testo in alto
        anchorX="center"
        anchorY="top"
      >
        Gabriele Strampelli
        {/*         {gradientTexture && <meshStandardMaterial map={gradientTexture} />}
         */}
      </Text>
      <ambientLight intensity={5} />
      <directionalLight position={[0, 1, 1]} intensity={5} />
      <rectAreaLight
        width={2.5}
        height={1.65}
        intensity={40}
        rotation={[0.1, Math.PI, 0]}
        position={[0, 0.55, -1.65]}
        ref={mbp16}
        color={"#1b9cf3"}
      />
      <primitive
        ref={mbp16}
        object={scene}
        position-y={[-1.7]}
        rotation-x={[0.3]}
        onClick={handleClick}
      ></primitive>
      <Text
        ref={textRef}
        font="./Tanker-Regular.ttf"
        fontSize={textMax} // Usa fontSize dinamico
        position={[0, -3.7, 0]} // Posiziona il testo in alto
        anchorX="center"
        anchorY="bottom"
      >
        Portfolio
        {/*         {gradientTexture && <meshStandardMaterial map={gradientTexture} />}
         */}
      </Text>
    </>
  );
};

export default Home;
