import React from "react";

const Button = ({ color, children, onClick }) => {
  // Stili del pulsante
  const buttonStyle = {
    color: color, // Colore del testo bianco di default
  };

  return (
    <button className="button" style={buttonStyle} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
