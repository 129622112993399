import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import * as THREE from "three";

const CameraAffari = () => {
  const { size } = useThree();
  const cursor = useRef({ x: 0, y: 0 });
  const targetDirection = useRef(new THREE.Vector3(0, 0, -1)); // Direzione iniziale della telecamera
  const { camera } = useThree();

  useEffect(() => {
    const handleMouseMove = (e) => {
      cursor.current = {
        x: e.clientX / size.width - 0.5,
        y: e.clientY / size.height - 0.5,
      };
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [size]);

  useFrame((_, delta) => {
    // Offset per mantenere il cursore al centro dello schermo
    const offsetX = -cursor.current.y * 2;
    const offsetY = cursor.current.x * 8.5;
    const newTargetDirection = new THREE.Vector3(offsetY, offsetX, -1); // Nuova direzione del target
    const smoothFactor = 2; // Fattore di smorzamento per un movimento più fluido

    // Interpolazione della direzione del target in modo fluido
    targetDirection.current.lerp(newTargetDirection, smoothFactor * delta);

    camera.lookAt(targetDirection.current); // Assicurati che la telecamera guardi verso il centro della scena
  });

  return null;
};

export default CameraAffari;
