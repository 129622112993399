import * as THREE from "three";
import { Suspense, useEffect, useRef, useState } from "react";
import useDeviceType from "../utilities/DeviceType";

const VideoBackground = ({ onVideoReady }) => {
  const videoRef = useRef();
  const planeRef = useRef();
  const [scale, setScale] = useState([1, 1, 1]);
  const [videoReady, setVideoReady] = useState(false); // Stato per monitorare se il video è pronto
  const isMobile = useDeviceType();

  useEffect(() => {
    const video = document.createElement("video");
    video.src = "/video_sfondo.mp4"; // Sostituisci con il percorso del tuo video
    video.loop = true;
    video.muted = true;
    video.playsInline = true;

    // Assegna la texture solo dopo che il video è pronto
    videoRef.current = new THREE.VideoTexture(video);

    // Quando il video è pronto, aggiorna lo stato
    video.oncanplaythrough = () => {
      setVideoReady(true); // Imposta il video come pronto
      videoRef.current.needsUpdate = true; // La texture è pronta
      video.play();
    };

    // Se il video non è ancora pronto, assicurati che il video venga caricato
    video.load();

    const handlePopState = () => {
      video.play();
    };

    window.addEventListener("popstate", handlePopState); // Intercetta l'evento
    return () => {
      window.removeEventListener("popstate", handlePopState); // Pulizia
    };
  }, []);

  useEffect(() => {
    // Aggiorna la scala in base alle dimensioni dello schermo
    const updateScale = () => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      setScale([aspectRatio * (isMobile ? 20 : 10), 10, 1]);
    };

    updateScale();
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, [isMobile]);

  useEffect(() => {
    // Verifica se la texture è stata applicata e se il video è pronto
    if (planeRef.current && videoReady) {
      // Applica la texture video al piano
      planeRef.current.material.map = videoRef.current;
      planeRef.current.material.needsUpdate = true; // Forza l'aggiornamento del materiale

      // Chiama onVideoReady() solo quando tutto è pronto
      onVideoReady();
    }
  }, [videoReady, planeRef.current]); // Dipende dallo stato videoReady e dalla texture del piano

  return (
    videoRef.current && (
      <mesh ref={planeRef} scale={scale} position={[0, 0, -2]}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial map={videoRef.current} toneMapped={false} />
      </mesh>
    )
  );
};

export default VideoBackground;
